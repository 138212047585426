<!-- (mouseover)="$event.stopPropagation()" -->
<div ngbDropdown class="d-inline-block">
	<!-- (mouseover)="showMenuHover(menuTypeIndex, menuItemsId)" -->
	<!-- (focusout)="hideMenuFocusOut(menuTypeIndex, menuItemsId)" -->
	<button
		class="btn dropdown-toggle custom-dropdown-menu-nav"
		style="display: flex; align-items: center"
		(click)="showMenuHover(menuTypeIndex, menuItemsId)"
		id="{{ menuItemsId }}"
		ngbDropdownToggle
		[ngClass]="{ 'login-title': menuType == 'login' }">
		<span
			class="filter-option pull-left drop-down-title float-right font-weight-bold big-size-button"
			[ngClass]="{ 'float-right': isArabic(), 'upper-case': menuType == 'language' }">
			<span *ngIf="menuType == 'login'">
				<img
					[src]="logo"
					class="rounded-avatar"
					[ngClass]="{ 'rounded-avatar-clear-margin': isArabic() }"
					alt="image" />&nbsp;
			</span>
			{{ title }} </span
		>&nbsp;
		<span class="caret pull-right float-left" [ngClass]="{ 'float-left': isArabic() }"></span>
	</button>

	<!-- large menu -->
	<div
		class="dropdown-menu animated fadeIn open dd-ab show-drop-down-menu large-menu-dropdown arrow-top"
		[ngClass]="{
			'lang-menu': menuType == 'language',
			'currency-menu': menuType == 'currency',
			'login-menu': menuType == 'login',
			'show-drop-down-menu-100': isB2C,
			'show-custom': shareVarsRef.showMenuNav[menuTypeIndex],
			'hide-custom-dpd': !shareVarsRef.showMenuNav[menuTypeIndex],
			'flight-menu':
				menuType == 'flight' ||
				menuType == 'hotel' ||
				menuType == 'package' ||
				menuType == 'visa' ||
				menuType == 'insurance' ||
				menuType == 'charter' ||
				menuType == 'analytics'
		}"
		ngbDropdownMenu
		attr.aria-labelledby="{{ menuItemsId }}"
		style="min-width: 30px !important">
		<button
			class="dropdown-item dd-ab-button custom-btn-hover"
			*ngFor="let elm of menuItems; let elmIndex = index"
			id="nav_menu_dropdown_item_{{ elmIndex }}_{{ elm }}"
			(click)="actionClick(elm)">
			<span *ngIf="menuType != 'login'">
				<img src="{{ getLangIcon(elm) }}" class="little-flag" />
			</span>

			<span *ngIf="menuType == 'login'">
				<i *ngIf="elm == 'DASHBOARD'" class="fa fa-user"></i>
				<i *ngIf="elm == 'LOGOUT'" class="fa fa-sign-out"></i>
			</span>

			<span> &nbsp;&nbsp;{{ elm | translate }} </span>
		</button>
		<br />
	</div>

	<!-- small menu -->
	<div class="small-menu-dropdown" ngbDropdownMenu attr.aria-labelledby="{{ menuItemsId }}">
		<button
			class="dropdown-item dd-ab-button custom-btn-hover"
			*ngFor="let elm of menuItems; let elmIndex = index"
			id="nav_menu_dropdown_item_{{ elmIndex }}_{{ elm }}"
			(click)="actionClick(elm)">
			<span *ngIf="menuType != 'login'">
				<img src="{{ getLangIcon(elm) }}" class="little-flag" />
			</span>

			<span *ngIf="menuType == 'login'">
				<i *ngIf="elm == 'DASHBOARD'" class="fa fa-user"></i>
				<i *ngIf="elm == 'LOGOUT'" class="fa fa-sign-out"></i>
			</span>

			<span> &nbsp;&nbsp;{{ elm | translate }} </span>
		</button>
		<br />
	</div>
</div>
