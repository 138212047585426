<div class="login-wrapper">
	<form ngNativeValidate [ngClass]="{'arabic-css': isArabic()}">
		<!--         <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            <div class="col-md-10 col-sm-10 col-xs-12 search-col-padding">
                <label style="width: 100%; font-size: small; margin-top: 10px;">{{"EMAIL"|translate}}</label>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
        </div>

        <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            <div class="col-md-10 col-sm-10 col-xs-12 search-col-padding">
                <input type="email" [(ngModel)]="email" style="height: 40px; width:100%; padding: 10px; border: 1px solid #ccc;" name="login_user" id="login_email_input" required>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
        </div>
 -->
		<div class="row">
			<div class="col-xs-12 search-col-padding fpt">
				<div class="sm-styles">
					<label for="login_email_input">{{"EMAIL"|translate}}</label>
					<div class="input-group">
						<input
							type="email"
							[(ngModel)]="email"
							name="login_user"
							id="login_email_input"
							class="form-control input-email"
							required
							autofocus />
					</div>
				</div>
			</div>
		</div>
		<!--         <div class="row">

            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            <div class="col-md-10 col-sm-10 col-xs-12 search-col-padding">
                <label style="width:100%; margin-top : 10px; font-size: small;">{{"PASSWORD"|translate}}</label>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
        </div>

        <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            <div class="col-md-10 col-sm-10 col-xs-12 search-col-padding">
                <input [(ngModel)]="password" type="password" style="height: 40px; width:100%; padding: 10px; border: 1px solid #ccc;" name="login_password" id="login_password_input" required>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
        </div> -->
		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="login_password_input">{{"PASSWORD"|translate}}</label>
					<div class="input-group">
						<input
							[(ngModel)]="password"
							type="password"
							class="form-control"
							name="login_password"
							id="login_password_input"
							required />
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 login-button-container">
				<button
					type="submit"
					style="width: 100%"
					class="search-button btn transition-effect login-button-xs"
					id="login_login_button"
					(click)="attempLogin()">
					{{"LOGIN"|translate}}&nbsp;&nbsp;
					<span *ngIf="!watingForLoginResponse"> <i class="fa fa-sign-in"></i></span>
					<span *ngIf="watingForLoginResponse"
						><i class="fa fa-spinner fa-fw fa-spin"></i
					></span>
				</button>
			</div>
		</div>

		<div class="row" *ngIf="hasLoginError">
			<div class="col-md-12 col-sm-12 col-xs-12 ng-danger-alert">
				{{"LOGIN_FAILED"|translate}}
			</div>
		</div>

		<div class="row" style="margin-top: 10px" *ngIf="showSignUp">
			<div class="col-md-1 col-sm-1 col-xs-12"></div>
			<div class="col-md-10 col-sm-10 col-xs-12 search-col-padding text-center">
				<a
					style="cursor: pointer"
					id="login_forget_password_link"
					(click)="goToForgetPassword.emit()"
					>{{"FORGOT_PW"|translate}}</a
				>
			</div>
			<div class="col-md-1 col-sm-1 col-xs-12"></div>
		</div>

		<div class="row" *ngIf="showSignUp">
			<div class="col-md-1 col-sm-1 col-xs-12"></div>
			<div class="col-md-10 col-sm-10 col-xs-12 search-col-padding text-center">
				<a style="cursor: pointer" (click)="goToSignUp.emit()" id="login_signup_link"
					>{{"CREATE_ACCOUNT"|translate}}</a
				>
			</div>
			<div class="col-md-1 col-sm-1 col-xs-12"></div>
		</div>
	</form>
</div>
