<!-- <app-loader [isBusy]="isBusy"></app-loader> -->

<div class="forget-password-wrapper">
	<div class="login-form">
		<div class="row">
			<div
				class="col-md-12 col-sm-12 col-xs-12 text-center reset-title"
				*ngIf="!sucessResetPWD">
				{{"FORGET_PWD_TITLE"|translate}}
			</div>

			<div
				class="col-md-12 col-sm-12 col-xs-12 text-center"
				style="font-size: 18px; color: black"
				*ngIf="sucessResetPWD">
				{{"FORGET_PWD_SUCCESS"|translate}}
			</div>

			<div class="col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="sucessResetPWD">
				<button
					style="width: 100%; margin-top: 50px; font-size: 15px"
					(click)="goToLoginPage()">
					{{"LOGIN"|translate}}
				</button>
			</div>
		</div>
		<div class="row">
			<form
				style="margin-top: 15px"
				#resetPWDForm="ngForm"
				novalidate
				[ngClass]="{'arabic-css': isArabic()}"
				*ngIf="!sucessResetPWD">
				<div class="sm-styles">
					<label for="login_username_input" *ngIf="type == 'b2b'"
						>{{"USERNAME"|translate}}</label
					>
					<label for="login_username_input" *ngIf="type == 'b2c'"
						>{{"EMAIL"|translate}}</label
					>
					<div class="input-group username-div">
						<input
							[type]="type == 'b2b' ? 'text' : 'email'"
							class="form-control"
							name="login_user"
							[(ngModel)]="username"
							id="login_username_input"
							required />
						<span class="input-group-addon" style="border: 1px solid #ccc"
							><i class="fa fa-user fa-fw"></i
						></span>
					</div>
				</div>
				<button
					[disabled]="resetPWDForm.form.invalid"
					style="border-radius: 12px; width: 100%; margin-top: 10px; font-size: 15px"
					id="reset_pwd_button"
					(click)="forgetPassword()">
					{{"FORGET_PWD_TITLE"|translate}}
				</button>
			</form>
		</div>
	</div>
</div>
