<app-loader-child *ngIf="watingServerResponse"></app-loader-child>
<div
	class="signup-wrapper"
	*ngIf="!shareVarsRef.signUpSucceeded"
	[ngClass]="{'arabic-css': isArabic() }">
	<form #signup_from="ngForm" novalidate>
		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="signup_username_input">{{"USERNAME"|translate}}</label>
					<div class="input-group">
						<input
							class="form-control input-email"
							type="text"
							[(ngModel)]="shareVarsRef.signUpUser.username"
							name="username"
							id="signup_username_input"
							required
							autofocus />
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="signup_firstname_input" class="">{{"FIRST_NAME"|translate}}</label>
					<div class="input-group">
						<input
							class="form-control"
							type="text"
							[(ngModel)]="shareVarsRef.signUpUser.firstname"
							#firstName="ngModel"
							name="firstname"
							id="signup_firstname_input"
							pattern="[a-zA-Z]{2,}"
							minlength="2"
							required />
						<p class="invalid" *ngIf="firstName.invalid && firstName.touched">
							{{"INVALID"|translate}} {{"FIRST_NAME"|translate}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="signup_lastname_input" class="">{{"LAST_NAME"|translate}}</label>
					<div class="input-group">
						<input
							type="text"
							[(ngModel)]="shareVarsRef.signUpUser.lastname"
							#lastName="ngModel"
							class="form-control"
							name="lastname"
							id="signup_lastname_input"
							pattern="[a-zA-Z]{2,}"
							minlength="2"
							required />
						<p class="invalid" *ngIf="lastName.invalid && lastName.touched">
							{{"INVALID"|translate}} {{"LAST_NAME"|translate}}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="signup_password_input" class="">{{"PASSWORD"|translate}}</label>
					<div class="input-group">
						<input
							type="password"
							[(ngModel)]="shareVarsRef.signUpUser.password"
							#passWord="ngModel"
							class="form-control"
							name="password"
							id="signup_password_input"
							minlength="8"
							required />
						<p class="invalid" *ngIf="passWord.invalid && passWord.touched">
							{{"INVALID"|translate}} {{"PASSWORD"|translate}}
							{{"PASSWORD_CONDITION"|translate}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="signup_r_password_input" class=""
						>{{"REPEAT_PASSWORD"|translate}}</label
					>
					<div class="input-group">
						<input
							type="password"
							class="form-control"
							[(ngModel)]="passwordVerification"
							name="r_password"
							id="signup_r_password_input"
							minlength="8"
							#passWordVerify="ngModel"
							required />
						<p class="invalid" *ngIf="passWordVerify.invalid && passWordVerify.touched">
							{{"INVALID"|translate}} {{"PASSWORD"|translate}} /
						</p>
						<p
							class="invalid"
							*ngIf="passWordVerify.touched && (shareVarsRef.signUpUser.password != passwordVerification)">
							{{"MATCHED_NEW_CONF_PWD"|translate}}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="signup_email_input" class="">{{"EMAIL"|translate}}</label>
					<div class="input-group">
						<input
							type="text"
							[(ngModel)]="shareVarsRef.signUpUser.email"
							#email="ngModel"
							class="form-control"
							name="email"
							id="signup_email_input"
							required
							pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
							placeholder="example@site.com" />
						<p class="invalid" *ngIf="email.invalid && email.touched">
							{{"INVALID"|translate}} {{"EMAIL"|translate}}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 search-col-padding">
				<div class="sm-styles">
					<label for="signup_phone_number_input" class=""
						>{{"PHONE_NUMBER"|translate}}</label
					>

					<div class="d-flex">
						<div class="">
							<div ngbDropdown class="d-inline-block">
								<button
									class="btn dropdown-toggle custom-select-button"
									id="signup_phone_code_class_dropdown"
									style="width: 100%"
									ngbDropdownToggle>
									<span class="filter-option pull-left">{{tempCountryCode}}</span
									>&nbsp;
									<span class="caret pull-right" style="margin-top: 8px"></span>
								</button>
								<div
									class="dropdown-menu open dd-ab"
									ngbDropdownMenu
									aria-labelledby="signup_phone_code_class_dropdown">
									<button
										*ngFor="let countryCode of countryCodeList; let countryCodeIndex = index"
										class="dropdown-item dd-ab-button"
										id="signup_code_{{countryCodeIndex}}_class_dropdown"
										(click)="setCountryCode(countryCode)">
										{{countryCode}}
									</button>
								</div>
							</div>
						</div>

						<div class="" style="flex: 1">
							<input
								type="number"
								[(ngModel)]="tempPhoneNumber"
								#phoneNumber="ngModel"
								class="form-control"
								placeholder="5 55 11 22 33"
								name="phone_number"
								pattern="[0-9]{9}"
								id="signup_phone_number_input"
								required />
							<p class="invalid" *ngIf="phoneNumber.invalid && phoneNumber.touched">
								{{"INVALID"|translate}} {{"PHONE_NUMBER"|translate}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-4 col-sm-12 col-xs-12"></div>
			<div class="col-md-4 col-sm-12 col-xs-12">
				<button
					type="submit"
					style="width: 100%"
					class="search-button btn transition-effect login-button-xs"
					id="signup_validate_button"
					[disabled]="signup_from.form.invalid"
					(click)="validate(signup_from.form)">
					{{"VALIDATE"|translate}}
				</button>
			</div>
			<div class="col-md-4 col-sm-12 col-xs-12"></div>
		</div>

		<div class="row center-content" *ngIf="shareVarsRef.signUpFailed">
			<div class="col-md-12 col-sm-12 col-xs-12 center-content ng-danger-alert">
				{{errorMessage|translate}}
			</div>
		</div>
	</form>
</div>

<div class="row center-content" *ngIf="shareVarsRef.signUpSucceeded">
	<div class="col-md-12 col-sm-12 col-xs-12 ng-success-alert center-content">
		<i class="fa fa-check-circle" style="color: #155724"></i
		>&nbsp;{{"SIGNUP_SUCCEEDED"|translate}}
	</div>
</div>
