<section>
	<div class="row page-title">
		<div class="clear-padding text-center flight-title flight-bg">
			<h1>{{ "FLIGHT_CALENDAR" | translate }}</h1>
		</div>
	</div>
</section>

<section *ngIf="data">
	<div class="calendar-container">
		<div class="filters-container">
			<h2 class="filters-header">Filters</h2>
			<div *ngIf="loading" style="margin: 2rem 0">
				<div style="text-align: center; font-weight: 400">
					{{ "SEARCHING_FLIGHT" | translate }} <br />
					<i class="fa loading-spinner fa-spinner"></i>
				</div>
			</div>
			<div class="filters-body" *ngIf="!loading">
				<div class="border-bottom">
					<h3 class="filter-title">Departure Stops</h3>
					<div *ngFor="let s of number_of_stops[0]; let i = index">
						<ng-container *ngIf="s">
							<label for="instop_{{ i }}" class="filter-label">
								<input
									type="checkbox"
									id="instop_{{ i }}"
									(click)="applyOutboundStopsFilter(i, $event)" />
								<span> {{ i == 0 ? "Direct" : i + " stops" }} </span>
							</label>
						</ng-container>
					</div>
				</div>

				<div *ngIf="request.tripType == 'rt'" class="border-bottom">
					<h3 class="filter-title">Return Stops</h3>
					<div *ngFor="let s of number_of_stops[1]; let i = index">
						<ng-container *ngIf="s">
							<label for="outstop_{{ i }}" class="filter-label">
								<input
									type="checkbox"
									id="outstop_{{ i }}"
									(click)="applyInboundStopsFilter(i, $event)" />
								<span> {{ i == 0 ? "Direct" : i + " stops" }} </span>
							</label>
						</ng-container>
					</div>
				</div>
				<div class="border-bottom">
					<h3 class="filter-title center-title" style="margin-bottom: 7px">Airlines</h3>
					<div class="airlines-grid">
						<div *ngFor="let airline of airlines; let i = index">
							<label
								for="air_{{ i }}"
								class="filter-label filter-label-margin"
								(click)="applyAirlineNamesFilter(airline, $event)">
								<input type="checkbox" id="air_{{ i }}" />
								<span>{{ airline }}</span>
								<img
									src="http://static.algebratec.com/assets/logos/airline/{{
										airline
									}}.png"
									style="width: 35px"
									alt="" />
							</label>
						</div>
					</div>
				</div>
				<div class="style-attest">
					<h3 class="filter-title">Attestation</h3>

					<label
						for="attestation"
						class="filter-label"
						(click)="applyCertificateFilter($event)">
						<input type="checkbox" id="attestation" />
						<span>Sans Attestation</span>
					</label>
				</div>
			</div>
		</div>

		<div class="scroll">
			<div class="table-container">
				<table *ngIf="request.tripType == 'rt'">
					<thead>
						<tr>
							<th colspan="2" class="no-borders"></th>
							<th
								class="header"
								style="padding: 1rem !important"
								[attr.colspan]="data.result.dates.outbound.length + 1">
								Départ
							</th>
						</tr>
						<tr>
							<th colspan="2" class="no-borders"></th>
							<th
								*ngFor="let date of data.result.dates.outbound"
								style="white-space: nowrap">
								{{ date }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th
								[attr.rowspan]="data.result.dates.inbound.length + 1"
								class="header">
								<div style="transform: rotate(-90deg)">Retour</div>
							</th>
						</tr>
						<tr
							*ngFor="
								let inbound of data.result.dates.inbound;
								let inboundIndex = index
							">
							<th style="white-space: nowrap">{{ inbound }}</th>
							<td
								*ngFor="
									let outbound of data.result.dates.outbound;
									let outboundIndex = index
								"
								class="table-data">
								<div class="hover-window" *ngIf="flightItem(outbound, inbound)">
									<p>
										{{ "DEPARTURE_GO" | translate }} :
										{{ getBounds(outbound, inbound, 1) }}
									</p>
									<p>
										{{ "RETURN" | translate }}:
										{{ getBounds(outbound, inbound, 2) }}
									</p>
									<p style="white-space: nowrap">
										{{ "CERTIFICATE" | translate }} :
										{{
											data.flightNeedExchangeDocuments
												? ("REQUIRED" | translate)
												: ("NOT_REQUIRED" | translate)
										}}
									</p>
									<p
										style="margin: 0; white-space: nowrap"
										*ngIf="
											flightItem(outbound, inbound).description != undefined
										">
										{{ flightItem(outbound, inbound).description[0] }}
									</p>
								</div>
								<label
									for="flight-choice_{{ outboundIndex }}_{{ inboundIndex }}"
									class="label-infos"
									*ngIf="flightItem(outbound, inbound)">
									<input
										type="radio"
										name="flight"
										value=""
										id="flight-choice_{{ outboundIndex }}_{{ inboundIndex }}"
										(click)="setSelectedFlight(outbound, inbound)" />
									<span
										style="white-space: nowrap"
										[style.color]="
											calculateRgb(getPriceDetailed(outbound, inbound)[0])
										">
										{{ formatPrice(outbound, inbound).join(" ") }}
									</span>
									<div style="width: 35px">
										<img
											style="width: 100%"
											src="http://static.algebratec.com/assets/logos/airline/{{
												getAirlineLogo(outbound, inbound)
											}}.png"
											alt="" />
									</div>
								</label>
								<label *ngIf="!flightItem(outbound, inbound)">/</label>
							</td>
						</tr>
					</tbody>
				</table>
				<table *ngIf="request.tripType == 'ow'">
					<thead>
						<tr>
							<th
								class="header"
								style="padding: 1rem !important"
								[attr.colspan]="data.result.dates.outbound.length + 1">
								Départ
							</th>
						</tr>
						<tr>
							<th
								*ngFor="let date of data.result.dates.outbound"
								style="white-space: nowrap">
								{{ date }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td
								*ngFor="
									let outbound of data.result.dates.outbound;
									let outboundIndex = index
								"
								class="table-data">
								<div class="hover-window" *ngIf="flightItem(outbound, null)">
									<p>
										{{ "DEPARTURE_GO" | translate }} :
										{{ getBounds(outbound, inbound, 1) }}
									</p>
									<p style="white-space: nowrap">
										{{ "CERTIFICATE" | translate }} :
										{{
											data.flightNeedExchangeDocuments
												? ("REQUIRED" | translate)
												: ("NOT_REQUIRED" | translate)
										}}
									</p>
									<p
										style="margin: 0; white-space: nowrap"
										*ngIf="
											flightItem(outbound, inbound).description != undefined
										">
										{{ flightItem(outbound, inbound).description[0] }}
									</p>
								</div>
								<label
									for="flight-choice_{{ outboundIndex }}"
									class="label-infos"
									*ngIf="flightItem(outbound)">
									<input
										type="radio"
										name="flight"
										value=""
										id="flight-choice_{{ outboundIndex }}"
										(click)="setSelectedFlight(outbound, null)" />
									<span
										style="white-space: nowrap"
										[style.color]="
											calculateRgb(getPriceDetailed(outbound, null)[0])
										">
										{{ getPriceDetailed(outbound, null).join(" ") }}
									</span>
									<div style="width: 35px">
										<img
											style="width: 100%"
											src="http://static.algebratec.com/assets/logos/airline/{{
												getAirlineLogo(outbound, null)
											}}.png"
											alt="" />
									</div>
								</label>
								<label *ngIf="!flightItem(outbound, null)">/</label>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="submit-button">
		<button
			type="button"
			class="btn-continue"
			[ngClass]="{ 'btn-disabled': !isSelected }"
			(click)="goToAvailability()"
			[disabled]="!isSelected">
			Continue
		</button>
	</div>
</section>
