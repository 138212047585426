<app-error-modal
	[errorModalShowModal]="varsRef.showError"
	[errorMessage]="varsRef.errorMessage"
	[showHome]="varsRef.showHome"
	[errorStatus]="varsRef.errorStatus"
	[errorTitle]="varsRef.errorTitle"
	[errorPayment]="varsRef.errorPayment">
</app-error-modal>

<app-loader-child
	#appLoaderChild
	[loaderChildMessage]="varsRef.childLoaderMessage"
	*ngIf="varsRef.showChildLoader">
</app-loader-child>

<app-tree-search *ngIf="getShowTreeSearch()"></app-tree-search>

<app-loader [isBusy]="getIsBusy()"></app-loader>

<app-navbar id="nav_container"></app-navbar>

<router-outlet></router-outlet>

<task-activity></task-activity>

<!-- <section id="test" style="margin: 30px">
    <div class="row">
        <app-column-display title="test 1" (buttonClick)="console.log('uo')"></app-column-display>
        <app-column-display title="test 2" (buttonClick)="console.log('uo')"></app-column-display>
        <app-column-display title="test 3" (buttonClick)="console.log('uo')"></app-column-display>
    </div>
</section>   -->
<app-footer></app-footer>
