export const environment = {
    production: true,
    CLIENTNAME: 'Algerie Booking',
    assets: 'algbooking',
    clientDisplayName: 'Algerie Booking',
    staticApi: 'https://static-api.algebratec.com/',
    serverURL: 'https://b2c-api.algebratec.com',
    phone: ['+213 23 11 61 80', '+213 23 11 61 81'],
    email: [],
    key: '2HeijriicyqGZxPuEEtb',    
    proAccessUrl: '',
    delivery: true,
    mode: 'B2C'
};
