<div [ngStyle]="{'display': !!hotel ? 'block' : 'none'}">
    <div class="task-hotel-loader" *ngIf="isLoading || isBooking">
        <i class="fa fa-spinner"></i>
    </div>
    <!-- Carousel -->

        <div *ngIf="showCarousel">
            <!-- Multi image -->
            <carousel
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 carousel-container"
                [showIndicators]="false"
                [interval]="false"
                [itemsPerSlide]="1"
                [startFromIndex]="carouselIndex"
                [singleSlideOffset]="true"
                *ngIf="hotel.images && hotel.images.length"
            >
            <span class="close-btn"><i class="fa fa-close" (click)="toggleCarousel()"></i></span>
                <slide *ngFor="let image of hotel['images']; let imageIndex = index">
                    <img
                        loading="lazy"
                        class="carousel-img-custom"
                        [src]="image['url']"
                        alt="{{ imageIndex + 1 }}"
                        onerror="this.onerror=null; this.src = './assets/images/hotel-not-found.jpg'"
                        style="display: block"
                        width="100%"
                        height="100%"
                        oncontextmenu="return false" />
                    <div class="carousel-caption" style="font-weight: bold; bottom: -27px">
                        <p>{{ imageIndex + 1 }}&nbsp;/&nbsp;{{ hotel["images"].length }}</p>
                    </div>
                </slide>
            </carousel>
            <!-- Single Image -->
            <carousel
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 carousel-container"
                [showIndicators]="false"
                [interval]="false"
                [itemsPerSlide]="1"
                [singleSlideOffset]="false"
                *ngIf="!(hotel.images && hotel.images.length)"
            >
            <span class="close-btn"><i class="fa fa-close" (click)="toggleCarousel()"></i></span>
                <slide>
                    <img
                        loading="lazy"
                        class="carousel-img-custom"
                        [src]="hotel.image['url']"
                        alt="{{ imageIndex + 1 }}"
                        onerror="this.onerror=null; this.src = './assets/images/hotel-not-found.jpg'"
                        style="display: block"
                        width="100%"
                        height="100%"
                        oncontextmenu="return false" />
                </slide>
            </carousel>
        </div>

    <div class="task-hotel-container" [ngStyle]="{'display': isLoading || isBooking ? 'none' : 'flex'}">
        <div style="width: 80vw;">
            <div class="task-hotel-images-container">
                <div class="task-hotel-header">
                    <div class="task-hotel-title">                    
                        <span class="hotel-task-provider">{{hotel.providerName}}</span>
                        <h2 style="text-shadow: #ddd 1px 1px;margin-top: 15px;">
                            {{ hotel.name }}<span *ngIf="hotel.combinedRooms"> (Combined)</span>
                        </h2>
                        <span style="display: inline-block" *ngIf="hotel.stars">
                            <span class="hotel-cat-span">
                                <i class="fa fa-star colored-yellow" *ngFor="let s of coloredStars"
                                    style="color: #f1c40f"></i>
                                <i class="fa fa-star-o colored-yellow" *ngFor="let s of nonColoredStars"
                                    style="color: #f1c40f"></i>
                            </span>
                        </span>
                        <div>
                            <app-tripadvisor-rating [rating]="hotel.tripadvisor" [reviews]="hotel.tripadvisor_reviews"
                            [task]="true" *ngIf="hotel.tripadvisor_reviews != 0"></app-tripadvisor-rating>     
                        </div><br>
                        <img src="{{ hotel.image }}"  style="
                            width: 100%;
                            box-shadow: #999 2px 2px 10px;
                            margin-bottom: 25px;"       
                            onerror="this.onerror=null; this.src = './assets/images/hotel-not-found.jpg'"
                            *ngIf="!(hotel.images && hotel.images.length)" />
                        <p>{{ hotel.address }}&nbsp;&nbsp;</p>
                        <div class="task-hotel-info">
                            <!-- <ng-container *ngIf="hotel.address"><i class="fa fa-map-marker"></i>{{hotel.address}}</ng-container><br> -->
                            <p *ngIf="hotel.phoneNumber">
                                <span style="background-color: rgba(41, 128, 185,1.0);" class="info-badge"><i class="fa fa-phone" style="color:white; padding-right: 10px"></i>{{ hotel.phoneNumber }}</span>
                            </p>
                            <p *ngIf="getEmails().length">
                                <span style="background-color: rgba(39, 174, 96,1.0);" class="info-badge"><i class="fa fa-envelope" style="color:white; padding-right: 10px"></i>{{ getEmails().join(" / ") }} </span>
                            </p>                                        
                        </div> 
                    </div>
                    <!-- <div class="task-hotel-single-image">
                        <img src="{{ hotel.image }}"
                            onerror="this.onerror=null; this.src = './assets/images/hotel-not-found.jpg'"
                            *ngIf="!(hotel.images && hotel.images.length)" />
                    </div> -->
                </div>
                <div class="task-images-container" *ngIf="hotel.images && hotel.images.length">
                    <ul>
                        <li *ngFor="let image of hotel.images; let imageIndex = index">
                            <img [src]="image.url" (click)="toggleCarousel(imageIndex)" />
                        </li>
                    </ul>
                </div>

                <div class="task-hotel-important-information" style="margin-top: 50px;" *ngIf="!!hotel.important_information && hotel.important_information.length">
                    <ul>
                        <li *ngFor="let info of hotel.important_information">
                            {{ info }}
                        </li>
                    </ul>
                </div>

                <div style="display: inline-block; margin-top: 50px; margin-bottom: 0px;   "
                    *ngIf="hotel.facilities && hotel.facilities.length != 0">
                        <span class="info-badge info-facilities"
                        *ngFor="let facility of hotel.facilities">{{ facility.code | translate }}</span>
                </div>
                <div class="task-hotel-description" [innerHTML]="hotel.description">                    
                </div>   

                <div class="task-hotel-rates" *ngIf="!hotel.combinedRooms">
                    <div class="task-hotel-title">
                        <h2 id="task-view-rates">{{ "ROOMS" | translate }}</h2>
                        <div>
                            <ul class="hotel-task-params">
                                <li>{{ "FROM1" | translate }}: {{ getHotelParams().checkIn }}  {{ "TO1" | translate }}: {{ getHotelParams().checkOut }}</li>                                
                                <li>{{ "ADULTS" | translate }}: {{ getPersons().adults }}        {{ "CHILDREN" | translate }}: {{ getPersons().children }} </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="task-hotel-rate" *ngFor="let rate of rates; let rateIndex = index">
                        <h2>
                            {{ "ROOM" | translate }} {{ "NUMBER" | translate }}
                            {{ rateIndex + 1 }}
                        </h2>
                        <!-- <span>{{ selectedRate[rateIndex].roomCode }}</span> -->
                        <div class="task-hotel-rooms" *ngFor="let rooms of rate; let roomsIndex = index">
                            <div class="task-hotel-room" *ngFor="let room of rooms; let roomIndex = index">
                                <div class="available-badge" *ngIf="!room.ondemand && !room.stopsale">disponible</div>
                                <div class="ondemand-badge" *ngIf="room.ondemand">
                                    {{ "ON_DEMAND" | translate }}
                                </div>
                                <div class="stopsale-badge" *ngIf="room.stopsale">
                                    Stop Sale
                                </div>
                                <div class="task-room-inner">
                                    <div class="task-room-details">
                                        <input style="width: 20px; height: 20px" type="radio"
                                            [name]="rateIndex+hotel.code+rate.toString()"
                                            [value]="room.rateKey"
                                            [defaultChecked]="roomsIndex==0&&roomIndex==0"
                                            (click)="selectRate(room, rateIndex)"
                                            [checked]="isSelectedRate(room, rateIndex)"
                                            [id]="'select-room-' + room.rateKey"
                                        />
                                        <label [htmlFor]="'select-room-' + room.rateKey">
                                            {{ hotel.rooms[room.roomCode] && hotel.rooms[room.roomCode].name }}
                                        </label>
                                        <div>
                                            <i class="fa fa-user" style="font-size: 20px" *ngFor="
                                                let adult of getPersonsMap(
                                                    room.adults
                                                )
                                            ">
                                            </i>
                                            <span style="padding-left: 10px" *ngIf="
                                                getPersonsMap(room.adults).length
                                            ">
                                                {{ room.adults }}
                                                {{ "ADULTS" | translate }}
                                            </span>
                                        </div>
                                        <div>
                                            <i class="fa fa-user" style="font-size: 15px" *ngFor="
                                                let child of getPersonsMap(
                                                    room.children
                                                )
                                            ">
                                            </i>
                                            <span style="padding-left: 10px" *ngIf="
                                                getPersonsMap(room.children)
                                                    .length
                                            ">
                                                {{ room.children }}
                                                {{ "CHILDREN" | translate }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="task-room-board">
                                        <h4>{{ room.boardName | translate }}</h4>
                                    </div>
                                    <div class="task-room-price">
                                            {{ formatHotelPrice(room.net.total) }}
                                            {{ room.net.currency }}
                                    </div>
                                </div>
                                <div class="cancellation-policy">
                                    <h4 (click)="selectCancellation(room.rateKey)">{{ "CANCELLATION_POLICY" | translate }}</h4>
                                    <div class="cancellation-container" *ngIf="isCancellationSelected(room.rateKey)">
                                        <!-- Loader -->
                                        <div *ngIf="isCancellationLoading">
                                            <i class="fa fa-spinner"></i>
                                        </div>
                                        <!-- Content -->
                                        <div *ngIf="!isCancellationLoading">
                                            <ul>
                                                <li *ngFor="let policy of fetchedCancellations[selectedCancellation]; let policyIndex = index">
                                                    <div class="col-xxl-12 col-md-12">
                                                        <span *ngIf="policy == 'error'">
                                                            {{ "ROOM_RATE_NOT_BOOKABLE_MESSAGE" | translate }}
                                                        </span>
                                    
                                                        <span *ngIf="policy['from']">
                                                            {{ "FROM1" | translate }} {{ policy["from"].slice(8, 10) }} -
                                                            {{ policy["from"].slice(5, 7) }} - {{ policy["from"].slice(0, 4) }}
                                                            {{ policy["from"].slice(11) }}
                                                        </span>
                                                        &nbsp;
                                    
                                                        <span *ngIf="policy['to']">
                                                            {{ "TO2" | translate }} {{ policy["to"].slice(8, 10) }} -
                                                            {{ policy["to"].slice(5, 7) }} - {{ policy["to"].slice(0, 4) }}
                                                            {{ policy["to"].slice(11) }}
                                                        </span>
                                                        &nbsp;&nbsp;
                                    
                                                        <span
                                                            class="free-cost confirmed"
                                                            *ngIf="!policy['chargeable'] && policy != 'error'">
                                                            {{ "FREE" | translate }}
                                                        </span>
                                    
                                                        <span
                                                            *ngIf="policy['chargeable'] && policy['fees_type'] == 'FIXED_PRICE'"
                                                            style="color: crimson">
                                                            <span *ngIf="policy['price']['total']">
                                                                {{ formatHotelPrice(policy["price"]["total"]) }}
                                                                {{ policy["price"]["currency"] }}
                                                            </span>
                                                        </span>
                                    
                                                        <span
                                                            *ngIf="policy['chargeable'] && policy['fees_type'] == 'PERCENT_PRICE'"
                                                            style="color: crimson">
                                                            {{ policy["percent_price"] }} %
                                                        </span>
                                    
                                                        <span
                                                            *ngIf="policy['chargeable'] && policy['fees_type'] == 'NIGHTS'"
                                                            style="color: crimson">
                                                            {{ policy["nights"] }} {{ "NIGHTS" | translate }}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="task-hotel-rooms" *ngIf="rooms.length && !hotel.combinedRooms">
                    <div class="task-hotel-title">
                        <h2>{{ "ROOMS" | translate }}</h2>
                    </div>
                    <table class="table">
                        <thead>
                            <th>Name</th>
                            <th>Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let room of rooms">
                                <td>{{ room.name }}</td>
                                <td>{{ getDescription(room) || "-" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
                <!-- Combined rooms -->
                <div class="task-hotel-rates" *ngIf="hotel.combinedRooms">
                    <div class="task-hotel-title">
                        <h2 id="task-view-rates">{{ "ROOMS" | translate }}</h2>
                        <div>
                            <ul class="hotel-task-params">
                                <li>{{ "FROM1" | translate }}: {{ getHotelParams().checkIn }} </li>
                                <li>{{ "TO1" | translate }}: {{ getHotelParams().checkOut }} </li>
                                <li>{{ "ADULTS" | translate }}: {{ getPersons().adults }} </li>
                                <li>{{ "CHILDREN" | translate }}: {{ getPersons().children }} </li>
                            </ul>
                        </div>
                    </div>
                    <div class="task-hotel-rate">
                        <h2 style="text-align: center; padding-top: 13px;">{{ "ROOM_COMBINATION" | translate }}</h2>
                        <div class="task-hotel-rooms" *ngFor="
                            let rate of combinedRates;
                            let rateIndex = index
                        ">
                            <div class="task-hotel-room">
                                <div class="available-badge">disponible</div>
                                <div class="ondemand-badge" *ngIf="rate.ondemand">
                                    {{ "ON_DEMAND" | translate }}
                                </div>
                                <div class="stopsale-badge" *ngIf="rate.stopsale">
                                    Stop Sale
                                </div>
                                <div class="task-room-inner">
                                    <div class="task-room-details" style="display: flex;max-width: 45%">
                                        <input style="width: 20px; height: 20px" [name]="hotel.code" type="radio"
                                            (click)="selectRateCombined(rate)" [defaultChecked]="
                                            rateIndex === 0
                                        " />
                                        <label style="padding-top: 0; font-weight: 500;">
                                            <ng-container *ngFor="
                                                let room of rate.roomCombination;
                                                let lastRoom = last
                                            ">
                                                {{ room.name }}
                                                <ng-container *ngIf="!lastRoom">
                                                    +
                                                </ng-container>
                                            </ng-container>
                                            <div>
                                                <i class="fa fa-user" style="font-size: 20px" *ngFor="
                                                    let adult of getPersonsMap(
                                                        getPersons().adults
                                                    )
                                                ">
                                                </i>
                                                <span style="padding-left: 10px;font-weight: lighter;" *ngIf="
                                                    getPersonsMap(getPersons().adults)
                                                        .length
                                                ">
                                                    {{ getPersons().adults }} {{ "ADULTS" | translate }}
                                                </span>
                                            </div>
                                            <div>
                                                <i class="fa fa-user" style="font-size: 15px" *ngFor="
                                                    let adult of getPersonsMap(
                                                        getPersons().children
                                                    )
                                                ">
                                                </i>
                                                <span style="padding-left: 10px;font-weight: lighter;" *ngIf="
                                                    getPersonsMap(getPersons().children)
                                                        .length
                                                ">
                                                    {{ getPersons().children }} {{ "CHILDREN" | translate }}
                                                </span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="task-room-board" style="padding-top: 0;">
                                        <h4>{{ rate.boardName | translate }}</h4>
                                    </div>
                                    <div class="task-room-price">                                    
                                            {{ formatHotelPrice(rate.net.total) }}
                                            {{ rate.net.currency }}                                    
                                    </div>
                                </div>
                                <div class="cancellation-policy">
                                    <h4 (click)="selectCancellation(rate.rateKey)">{{ "CANCELLATION_POLICY" | translate }}</h4>
                                    <div class="cancellation-container" *ngIf="isCancellationSelected(rate.rateKey)">
                                        <!-- Loader -->
                                        <div *ngIf="isCancellationLoading">
                                            <i class="fa fa-spinner"></i>
                                        </div>
                                        <!-- Content -->
                                        <div *ngIf="!isCancellationLoading">
                                            <ul>
                                                <li *ngFor="let policy of fetchedCancellations[selectedCancellation]; let policyIndex = index">
                                                    <div class="col-xxl-12 col-md-12">
                                                        <span *ngIf="policy == 'error'">
                                                            {{ "ROOM_RATE_NOT_BOOKABLE_MESSAGE" | translate }}
                                                        </span>
                                    
                                                        <span *ngIf="policy['from']">
                                                            {{ "FROM1" | translate }} {{ policy["from"].slice(8, 10) }} -
                                                            {{ policy["from"].slice(5, 7) }} - {{ policy["from"].slice(0, 4) }}
                                                            {{ policy["from"].slice(11) }}
                                                        </span>
                                                        &nbsp;
                                    
                                                        <span *ngIf="policy['to']">
                                                            {{ "TO2" | translate }} {{ policy["to"].slice(8, 10) }} -
                                                            {{ policy["to"].slice(5, 7) }} - {{ policy["to"].slice(0, 4) }}
                                                            {{ policy["to"].slice(11) }}
                                                        </span>
                                                        &nbsp;&nbsp;
                                    
                                                        <span
                                                            class="free-cost confirmed"
                                                            *ngIf="!policy['chargeable'] && policy != 'error'">
                                                            {{ "FREE" | translate }}
                                                        </span>
                                    
                                                        <span
                                                            *ngIf="policy['chargeable'] && policy['fees_type'] == 'FIXED_PRICE'"
                                                            style="color: crimson">
                                                            <span *ngIf="policy['price']['total']">
                                                                {{ formatHotelPrice(policy["price"]["total"]) }}
                                                                {{ policy["price"]["currency"] }}
                                                            </span>
                                                        </span>
                                    
                                                        <span
                                                            *ngIf="policy['chargeable'] && policy['fees_type'] == 'PERCENT_PRICE'"
                                                            style="color: crimson">
                                                            {{ policy["percent_price"] }} %
                                                        </span>
                                    
                                                        <span
                                                            *ngIf="policy['chargeable'] && policy['fees_type'] == 'NIGHTS'"
                                                            style="color: crimson">
                                                            {{ policy["nights"] }} {{ "NIGHTS" | translate }}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="height: 100px; width: 100%;"></div>
    <div class="task-total" *ngIf="!isLoading && !isBooking && totalPrice!=0">
        <div class="task-total-price">
            <h3>Total : {{ total }}</h3>
        </div>
        <div class="task-total-button-container">
            <button class="btn task-total-button" *ngIf="!hotel.combinedRooms" (click)="bookNotCombined()">Book
                now</button>
            <button class="btn task-total-button" *ngIf="hotel.combinedRooms" (click)="bookCombined()">Book now</button>
        </div>
    </div>
</div>