<app-modal
	title="{{'LOGIN'|translate}}"
	#logModal
	[showModal]="false"
	modalWidth="100"
	id="loginModal">
	<app-login
		#loginChild
		[showSignUp]="true"
		(goToSignUp)="showSignUp(logModal, signUpModal, forgetPasswordModal)"
		(goToForgetPassword)="showForgetPassword(logModal, signUpModal,forgetPasswordModal)"></app-login>
</app-modal>

<app-modal
	title="{{'SIGNUP'|translate}}"
	#signUpModal
	[showModal]="false"
	modalWidth="60"
	id="signupModal">
	<app-signup> </app-signup>
</app-modal>

<app-modal
	title="{{'FORGOT_PW'|translate}}"
	#forgetPasswordModal
	[showModal]="false"
	modalWidth="60">
	<app-forget-password [type]="'b2c'"> </app-forget-password>
</app-modal>

<div class="row transparent-menu nav-booking transparent-navbar" [ngClass]="{'arabic-css': isArabic()}">
	<div class="container clear-padding custom-menu-container">
		<div class="navbar-wrapper">
			<div class="navbar navbar-default" role="navigation">
				<div class="nav-container">
					<div class="navbar-header">
						<button type="button" class="navbar-toggle" (click)="showMenu = !showMenu">
							<span class="sr-only">Toggle navigation</span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
						</button>

						<a
							[routerLink]="HOME_ROUTE_URL"
							(click)="setLastActiveTab()"
							class="navbar-brand logo menu-nav-links" style="width: 150px;">
						</a>
					</div>

					<div
						class="navbar-collapse"
						[ngClass]="{'collapse': !showMenu, 'collapssing': showMenu }" style="color: white !important;">
						<ul
							class="nav navbar-nav navbar-right d-md-flex"
							style="align-items: center"
							[ngClass]="{'navbar-left-custom': isArabic()}">
							<!-- B2C Tabs -->
							<li style="margin-top: 10px;">
								<ul class="navbar-nav-list">
									<li [ngClass]="{'active': getLastSelectedTab()==1}">
										<a (click)="flightSearch()">Vols</a>
										<!-- <div class="sep" [ngClass]="{'active': getLastSelectedTab()==1}"> -->
											<!-- <hr> -->
										<!-- </div> -->
									</li>
									<li>
										<a (click)="hotelSearch()">Hotels</a>
										<!-- <div class="sep" [ngClass]="{'active': getLastSelectedTab()==2}"> -->
											<!-- <hr> -->
										<!-- </div> -->
									</li>
								</ul>
							</li>

							<!-- FLIGHT -->
							<li
								*ngIf="isB2BUser() &&
									shareVarsRef.checkAuthorization( 'AUTHORIZED_TO_USE_FLIGHT_BOOKING') ">
								>
								<a
									class="menu-nav-links"
									[ngClass]="{'active': getLastSelectedTab()==1}"
									style="padding: 0">
									<app-navbar-menu
										[menuType]="'flight'"
										(newBooking)="flightSearch()"
										(flightSchedule)="flightSchedule()"
										(manageBookings)="manageFlightBookings()"
										(amadeusMarkup)="goToFlightAmadeusMarkup()"
										[menuTypeIndex]="3"
										[title]="'FLIGHTS'|translate"
										[menuItems]="flightArray"
										[menuItemsId]="'nav_menu_item_flight'">
									</app-navbar-menu>
								</a>
							</li>
							<!--                             <li *ngIf="!isB2BUser() && checkFlightAuthorization()">
                                <a class="menu-nav-links" [ngClass]="{'active': shareVarsRef.lastActiveTab==1}"
                                   (click)="showFlightTab()">{{"FLIGHTS"|translate}}</a>
                            </li> -->
							<!-- END FLIGHT -->

							<!-- VACANCE -->
							<li
								*ngIf="isB2BUser() &&
									shareVarsRef.checkAuthorization( 'AUTHORIZED_TO_USE_PACKAGE_BOOKING') ">
								<a
									class="menu-nav-links"
									[ngClass]="{'active': getLastSelectedTab()==2}"
									style="padding: 0">
									<app-navbar-menu
										[menuType]="'package'"
										(newBooking)="packageSearch()"
										(manageBookings)="managePackageBookings()"
										(managePackages)="goToManagePackages()"
										[menuTypeIndex]="5"
										[title]="'HOLIDAYS'|translate"
										[menuItems]="packageArray"
										[menuItemsId]="'nav_menu_item_package'">
									</app-navbar-menu>
								</a>
							</li>
							<!--                            <li *ngIf="!isB2BUser() && checkPackageAuthorization()">-->
							<!--                                <a class="menu-nav-links" [ngClass]="{'active': shareVarsRef.lastActiveTab==2}" (click)="showPackageTab()">{{"HOLIDAYS"|translate}}</a>-->
							<!--                            </li>-->
							<!-- END VACANCE-->

							<!-- HOTEL -->
							<!--                             <li *ngIf="isB2BUser() && checkHotelAuthorization()">
                                <a class="menu-nav-links" [ngClass]="{'active': getLastSelectedTab()==3}"
                                    style="padding: 0;">
                                    <app-navbar-menu [menuType]="'hotel'" (newBooking)="hotelSearch()"
                                        (manageBookings)="manageHotelBookings()" [menuTypeIndex]="4"
                                        [title]="'HOTELS'|translate" [menuItems]="hotelArray"
                                        [menuItemsId]="'nav_menu_item_hotel'">
                                    </app-navbar-menu>
                                </a>
                            </li>
                            <li *ngIf="!isB2BUser() && checkHotelAuthorization()">
                                <a class="menu-nav-links" [ngClass]="{'active': shareVarsRef.lastActiveTab==3}"
                                   (click)="showHotelTab()">{{"HOTELS"|translate}}</a>
                            </li> -->
							<!-- END HOTEL -->

							<!-- CHARTER -->
							<li
								*ngIf="isB2BUser() &&
									shareVarsRef.checkAuthorization( 'AUTHORIZED_TO_USE_CHARTER_FLIGHT') ">
								<a
									class="menu-nav-links"
									[ngClass]="{'active': getLastSelectedTab()==7}"
									style="padding: 0">
									<app-navbar-menu
										[menuType]="'charter'"
										(newBooking)="charterSearch()"
										(manageBookings)="goToManageCharterPage()"
										[menuTypeIndex]="7"
										[title]="'CHARTER'|translate"
										[menuItems]="charterArray"
										[menuItemsId]="'nav_menu_item_charter'">
									</app-navbar-menu>
								</a>
							</li>
							<!-- END CHARTER -->

							<!-- INSURANCE -->
							<li
								*ngIf="isB2BUser() && shareVarsRef.checkAuthorization('AUTHORIZED_TO_USE_INSURANCE')">
								<a
									class="menu-nav-links"
									[ngClass]="{'active': getLastSelectedTab()==5}"
									style="padding: 0">
									<app-navbar-menu
										[menuType]="'insurance'"
										(newContract)="insuranceSearch()"
										(manageContracts)="goToManageInsurancePage()"
										[menuTypeIndex]="6"
										[title]="'INSURANCE'|translate"
										[menuItems]="insuranceArray"
										[menuItemsId]="'nav_menu_item_flight'">
									</app-navbar-menu>
								</a>
							</li>
							<li
								*ngIf="!isB2BUser() && shareVarsRef.checkAuthorization('AUTHORIZED_TO_USE_INSURANCE')">
								<a
									class="menu-nav-links"
									[ngClass]="{'active': shareVarsRef.lastActiveTab==4}"
									(click)="showFlightTab()"
									>{{"INSURANCE"|translate}}</a
								>
							</li>
							<!-- END INSURANCE -->

							<!-- VISA -->
							<li
								*ngIf="isB2BUser() &&
									shareVarsRef.checkAuthorization( 'AUTHORIZED_TO_USE_VISA_BOOKING') ">
								<a
									class="menu-nav-links"
									[ngClass]="{'active': getLastSelectedTab() == 8}"
									style="padding: 0">
									<app-navbar-menu
										[menuType]="'visa'"
										(newBooking)="visaSearch()"
										(manageBookings)="manageVisaBookings()"
										[menuTypeIndex]="8"
										[title]="'VISA'|translate"
										[menuItems]="visaArray"
										[menuItemsId]="'nav_menu_item_visa'">
									</app-navbar-menu>
								</a>
							</li>

							<li
								*ngIf="!isB2BUser() &&
									shareVarsRef.checkAuthorization( 'AUTHORIZED_TO_USE_VISA_BOOKING') ">
								<a
									class="menu-nav-links"
									[ngClass]="{'active': shareVarsRef.lastActiveTab == 8}"
									(click)="showVisaTab()"
									>{{"VISA"|translate}}</a
								>
							</li>

							<li *ngIf="currentPlatform && currentPlatform.help_desk_url">
								<a
									class="menu-nav-links"
									href="{{currentPlatform.help_desk_url}}"
									target="_blank"
									>Support Helpdesk</a
								>
							</li>

							<li
								*ngIf="checkDisabledNavButtons() && isB2B"
								class="separator hide-on-small-screen"></li>

							<!-- REQUEST B2B ACCOUNT -->
							<!-- <li *ngIf="!isB2BUser() && isB2B">
								<a
									class="menu-nav-links"
									(click)="goToB2BRegister()"
									[ngClass]="{'active': getLastSelectedTab()==0}"
									>{{"REQUEST_B2B"|translate}}</a
								>
							</li> -->
							<!-- END REQUEST B2B ACCOUNT -->

							<li *ngIf="!isB2BUser()" class="separator hide-on-small-screen"></li>

							<!-- USER B2B SETTINGS -->
							<!-- <li *ngIf="isLogged && isB2BUser()">
								<a
									class="signup-login-nav menu-nav-links isLogged"
									*ngIf="currentUser"
									style="padding: 0 !important">
									<span>
										<span class="inline-display" *ngIf="isLogged">
											<app-navbar-menu
												(logOut)="logoutB2B()"
												(changePassword)="changePassword()"
												(agencyDashboard)="goToAgencyDashboard()"
												[menuType]="'login'"
												[menuTypeIndex]="0"
												[logo]="currentAgency.logo ? staticUrl+currentAgency.logo : 'assets/images/user.jpg'"
												[title]="currentAgency.name"
												[menuItems]="loginArrayB2B"
												[menuItemsId]="'nav_menu_item_login'">
											</app-navbar-menu>
										</span>
									</span>
								</a>
							</li> -->
							<!-- END USER B2B SETTINGS -->

							<!-- CONNECTION B2C -->
							<!-- <li *ngIf="showConnect && !isB2BUser()">
								<a
									class="signup-login-nav menu-nav-links"
									[ngClass]="{'zero-padding': isLogged}"
									(click)="showLogin(logModal)">
									<span>
										<span *ngIf="!isLogged"> {{"CONNECT"|translate}} </span>

										<span *ngIf="isLogged" class="inline-display">
											<app-navbar-menu
												(logOut)="logout()"
												(goToDashboard)="showUserDashboard()"
												[menuType]="'login'"
												[menuTypeIndex]="0"
												[title]="currentUser.name"
												[menuItems]="loginArrayB2C"
												[menuItemsId]="'nav_menu_item_login'"
												[logo]="currentUser.avatar ? staticUrl+currentUser.avatar : 'assets/images/user.jpg'"
												[isB2C]="true">
											</app-navbar-menu>
										</span>
									</span>
								</a>
							</li> -->
							<!-- END CONNECTION B2C -->

							<!-- LANGUAGE -->
							<!-- LARGE SCREEN -->
							<li class="hide-on-small-screen">
								<a class="menu-nav-links" style="padding: 0 !important">
									<app-navbar-menu
										[menuType]="'language'"
										[menuTypeIndex]="1"
										[title]="currentLang"
										[menuItems]="languageArray"
										[menuItemsId]="'nav_menu_item_language'">
									</app-navbar-menu>
								</a>
							</li>
							<!-- MOBILE -->
							<li class="nav-mobile-lang">
								<button (click)="changeLanguage('ar')">AR</button>
								<button (click)="changeLanguage('fr')">FR</button>
								<button (click)="changeLanguage('en')">EN</button>
							</li>
							<!-- END LANGUAGE -->

							<!-- CURRENCY
                            <li>
                                <a class="menu-nav-links" style="padding: 0 !important;">
                                    <app-navbar-menu [menuType]="'currency'" [menuTypeIndex]="2" [title]="currentCurrency" [menuItems]="currencyArrayM" [menuItemsId]="'nav_menu_item_currency'">
                                    </app-navbar-menu>
                                </a>
                            </li>
                            END CURRENCY -->

							<!-- <li *ngIf="isLogged && isB2BUser()">
								<a
									class="menu-nav-links"
									style="font-weight: bold; text-align: end"
									(click)="goToCommunicationsPage()">
									<i class="fa fa-envelope mail-counter"></i>
									<span
										class="mail-counter-badge"
										*ngIf="baseService.unreadMessages>0"
										>{{baseService.unreadMessages}}</span
									>
								</a>
							</li> -->

							<!-- <li *ngIf="isLogged && isB2BUser()">
                                <a class="menu-nav-links" style="font-weight: bold;text-align:end"
                                    (click)="goToInvoicesPage()">
										<svg xmlns="http://www.w3.org/2000/svg"style="
										width: 21px;
										aspect-ratio: 1/1;
										fill: var(--navbar-item-color);
										transform: translateY(2.5px);
										"

									viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                                    <span class="mail-counter-badge" *ngIf="unreadInvoices>0">{{unreadInvoices}}</span>

                                </a>
                            </li> -->

							<!-- <li *ngIf="isLogged && isB2BUser()">
								<a
									class="menu-nav-links"
									style="font-weight: bold; text-align: end">
									{{formatPrice(shareVarsRef.b2bLocalDepositBalance)}}&nbsp;{{currentAgency.local_currency_code}}<br />
									<p class="overdraw-text">
										{{"AUTHORIZED_OVERDRAW" | translate}}
									</p>
									{{formatPrice(shareVarsRef.b2bOverdraw)}}&nbsp;{{currentAgency.local_currency_code}}
								</a>
							</li> -->

							<!-- <li *ngIf="isLogged && !isB2BUser()">
								<a
									class="menu-nav-links"
									style="font-weight: bold; text-align: end"
									title="Utilisable uniquement sur votre premier vols international">
									{{formatPrice(2000)}} DZD
								</a>
							</li> -->
							<li *ngIf="!isLogged && showProAccess()">
								<a (click)="goToB2BLogin()" id="pro-access">
									<i class="fa fa-lock"></i> {{"PRO_ACCESS"|translate}}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
