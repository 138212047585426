<div class="tree-container">
	<div class="container margin-tree">
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-header">
				<i
					class="fa fa-close close"
					placement="top"
					container="body"
					triggers="mouseenter:mouseout"
					(click)="closeTreeSearch()"></i>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-spinner">
				<div class="loader" *ngIf="isbusy || isbusyContinent">
					<i class="fa fa-spinner fa-fw fa-spin" placement="top" container="body"></i>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-body" *ngIf="!isbusyContinent">
				<!-- <div class="continent">
                  <ul>
                      <li [ngClass]="{'active-continent': continent==='africa'}" (click)="chooseContinent('africa')">AFRIQUE</li>
                      <li [ngClass]="{'active-continent': continent==='europe'}"(click)="chooseContinent('europe')">EUROPE</li>
                      <li [ngClass]="{'active-continent': continent==='namerica'}"(click)="chooseContinent('namerica')">AMERIQUE DU NORD</li>
                      <li [ngClass]="{'active-continent': continent==='samerica'}"(click)="chooseContinent('samerica')">AMERIQUE DU SUD</li>
                      <li [ngClass]="{'active-continent': continent==='asia'}"    (click)="chooseContinent('asia')">ASIE</li>
                      <li [ngClass]="{'active-continent': continent==='oceania'}"(click)="chooseContinent('oceania')">OCEANIE</li>                    
                  </ul>
              </div> -->
				<div class="continent">
					<ul>
						<ng-container *ngFor="let e of continentsCode">
							<li
								[ngClass]="{ 'active-continent': continent === e.name }"
								(click)="chooseContinent(e.name)">
								{{ e.name.toUpperCase() }}
							</li>
						</ng-container>

						<!-- <li [ngClass]="{'active-continent': continent==='europe'}"(click)="chooseContinent('europe')">EUROPE</li>
                    <li [ngClass]="{'active-continent': continent==='namerica'}"(click)="chooseContinent('namerica')">AMERIQUE DU NORD</li>
                    <li [ngClass]="{'active-continent': continent==='samerica'}"(click)="chooseContinent('samerica')">AMERIQUE DU SUD</li>
                    <li [ngClass]="{'active-continent': continent==='asia'}"    (click)="chooseContinent('asia')">ASIE</li>
                    <li [ngClass]="{'active-continent': continent==='oceania'}"(click)="chooseContinent('oceania')">OCEANIE</li>                     -->
					</ul>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-body">
				<div
					[ngClass]="{
						countries: true,
						opened: countriesStatus === 'open',
						closed: countriesStatus === 'closed'
					}">
					<h4
						(click)="changeCountriesStatus()"
						style="cursor: pointer"
						*ngIf="continent != ''">
						{{ "COUNTRY" | translate }} {{ country.name }}
					</h4>
					<h4 style="color: grey" *ngIf="continent == ''">
						{{ "NO_CONTINENT_SELECTED" | translate }}
					</h4>
					<div class="overflow-ul">
						<ul style="list-style-type: circle">
							<li *ngFor="let country of countries">
								<a (click)="selectCountry(country)">{{ country.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-body">
				<div
					[ngClass]="{
						cities: true,
						opened: provincesStatus === 'open',
						closed: provincesStatus === 'closed'
					}">
					<h4
						(click)="changeProvincesStatus()"
						style="cursor: pointer"
						*ngIf="country != ''">
						{{ "PROVINCES_OF" | translate }} {{ country.name }}
					</h4>
					<h4 style="color: grey" *ngIf="country == ''">
						{{ "NO_COUNTRY_SELECTED" | translate }}
					</h4>
					<div class="overflow-ul">
						<ul>
							<li>
								<a (click)="selectZone(country)">{{
									"ALL_PROVINCES" | translate
								}}</a>
							</li>
							<li *ngFor="let province of provinces">
								<a (click)="selectProvince(province)">{{ province.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-body">
				<div
					[ngClass]="{
						cities: true,
						opened: citiesStatus === 'open',
						closed: citiesStatus === 'closed'
					}">
					<h4
						(click)="changeCitiesStatus()"
						style="cursor: pointer"
						*ngIf="province != ''">
						{{ "CITIES_OF" | translate }} {{ province.name }}
					</h4>
					<h4 style="color: grey" *ngIf="province == ''">
						{{ "NO_PROVINCE_SELECTED" | translate }}
					</h4>
					<div class="overflow-ul">
						<ul>
							<li>
								<a (click)="selectZone(province)">{{ "ALL_CITIES" | translate }}</a>
							</li>
							<li *ngFor="let city of cities">
								<a (click)="selectCity(city)">{{ city.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-body">
				<div
					[ngClass]="{
						zones: true,
						opened: zonesStatus === 'open',
						closed: zonesStatus === 'closed'
					}">
					<h4 (click)="changeZonesStatus()" style="cursor: pointer" *ngIf="city != ''">
						{{ "ZONES_OF" | translate }} {{ city.name }}
					</h4>
					<h4 style="color: grey" *ngIf="city == ''">
						{{ "NO_CITY_SELECTED" | translate }}
					</h4>
					<div class="overflow-ul">
						<ul>
							<li>
								<a (click)="selectZone(city)">{{ "ALL_ZONES" | translate }}</a>
							</li>
							<li *ngFor="let zone of zones">
								<a (click)="selectZone(zone)">{{ zone.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10 modal-footers">
				<span style="color: #eff0f1">JJJJJ</span>
			</div>
			<div class="col-md-1"></div>
		</div>
	</div>
</div>
