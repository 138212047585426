<div class="container">
	<div class="row">
		<div class="full-loading col-xxl-12 col-md-12 col-sm-12 col-xs-12" *ngIf="isBusy">
			<div class="centered-loader">
				<div class="centered-logo col-xxl-12 col-md-12 col-sm-12 col-xs-12">
					<h2>{{ clientName }}</h2>
				</div>
				<div
					class="centered-logo loading-animation centered-box col-xxl-12 col-md-12 col-sm-12 col-xs-12">
					<div class="center-spans">
						<span><i class="fa fa-plane"></i></span>
						<span><i class="fa fa-bed"></i></span>
						<span><i class="fa fa-ship"></i></span>
						<span><i class="fa fa-suitcase"></i></span>
					</div>
				</div>
				<!-- <div class="search-title centered-title col-xxl-12 col-md-12 col-sm-12 col-xs-12">
                    <p>
                        {{shareVarsRef.mainLoaderMessage|translate}}
                    </p>
                </div> -->
			</div>
		</div>
	</div>
</div>
