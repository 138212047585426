<app-modal
	[title]="'TRANSFER' | translate"
	#eTicketTransfertModal
	[showModal]="showModalTransfert"
	modalWidth="50"
	(closeModalFrom2NDParent)="showModalTransfert = false">
	<div class="col-md-12 col-xs-12 col-sm-12 text-center" *ngIf="showModalTransfert">
		<div class="color-4 col-md-12 col-sm-12 col-xs-12 clear-padding">
			<label class="margin-15-b">
				{{ "AGENCY" | translate }}
			</label>
			<br />
			<app-shared-commun-dropdown
				[searchSource]="'agency-panel'"
				[mainButtonID]="'flight_manage_bookings_agencies_dropdown'"
				[mainButtonText]="agenciesListText[0]"
				[dropdownAriaID]="'flight_manage_bookings_agencies_dropdown_aria'"
				[ariaButtonsID]="agenciesListID"
				[ariaButtonsText]="agenciesListText"
				(clickEvent)="getSelectedAgency($event)"
				[useShowDropdownMenu]="true">
			</app-shared-commun-dropdown>
		</div>

		<div class="col-md-12 col-sm-12 col-xs-12 text-center login-form" style="padding: 0">
			<button
				style="margin: 40px"
				[disabled]="selectedAgencyIndex == null"
				(click)="transfertTicket()">
				{{ "CONTINUE" | translate }}
			</button>
		</div>
	</div>
</app-modal>

<app-flight-voucher-shared
	*ngIf="modalElement"
	[(modalElement)]="modalElement"
	[pageType]="'ticket'"
	[tripType]="tripType"
	(printTicketEvent)="printTicket($event)"
	(sendTicketEmailEvent)="sendBookingByEmail($event)"
	(confirmBookingEvent)="confirmBooking()"
	(transfertTicketEvent)="openTransfertTicket()"
	(synchronizeEvent)="synchronize()"
	(refundEticketEvent)="refundEticket()"
	(cancelEticketEvent)="cancelEticket()"
	(applyPenaltyCancelTicketAdminEvent)="applyPenaltyCancelTicketAdmin($event)">
</app-flight-voucher-shared>

<div *ngIf="modalElement">
	<div class="container edit-ticket-area">
		<a
			class="edit-ticket-button text-center"
			target="_blank"
			href="https://forms.gle/hoy5h8E8cdPtN5Dc7"
			>Modifier mon billet</a
		>
	</div>
	<div class="container edit-ticket-area">
		<a
			class="cancel-ticket-button text-center"
			target="_blank"
			href="https://forms.gle/hoy5h8E8cdPtN5Dc7"
			>Annuler mon billet</a
		>
	</div>
</div>
<div *ngIf="errorMessage" style="margin: 2rem">
	<p class="alert alert-danger">{{ errorMessage | translate }}</p>
</div>
