<div ngbDropdown class="d-inline" [ngClass]="{'arabic-css': isArabic()}" id="{{mainWrapperID}}">
	<button
		(focusout)="addSearch ? null : closeDPD()"
		class="btn dropdown-toggle custom-select-button dropdown-buttton auto-height"
		id="{{mainButtonID}}"
		(click)="openDPDNew()"
		[disabled]="isDisabled"
		[ngClass]="{'custom-select-button-transparent': searchSource=='flight_availability' || searchSource=='flight',
  'custom-select-button-white': searchSource=='flight', 'border-flight-passenger-dpd': searchSource=='flight-passenger'}"
		ngbDropdownToggle>
		<span class="filter-option pull-left">
			<span class="test" *ngIf="dropdownTypeString">{{mainButtonText|translate}}</span>
			<span
				class="test"
				*ngIf="!dropdownTypeString && ariaButtonsTextTranslation === undefined"
				>{{mainButtonText}}</span
			>
			<span
				class="test"
				*ngIf="!dropdownTypeString && ariaButtonsTextTranslation !== undefined"
				>{{ariaButtonsTextTranslation[mainButtonText]}}</span
			> </span
		>&nbsp;
		<span class="caret pull-right"></span>
	</button>

	<div
		*ngIf="showDropdownMenu"
		class="dropdown-menu open dd-ab dropdown-list volz-override"
		ngbDropdownMenu
		[ngStyle]="{'max-height': searchSource == 'hotel_availability_rooms' ? '82px' : 'auto'}"
		attr.aria-labelledby="{{mainButtonID}}"
		id="{{dropdownAriaID}}">
		<input
			type="text"
			class="search-input-dpd"
			placeholder='{{"SEARCH" |translate}}'
			[(ngModel)]="searchData"
			*ngIf="addSearch" />

		<button
			class="dropdown-item dd-ab-button dd-ab-button-custom"
			*ngFor="let btnDpd of ariaButtonsText | searchFilterPipe: searchData; let btnDpdIndex=index"
			id="{{ariaButtonsID[btnDpdIndex]}}_{{btnDpdIndex}}"
			(click)="sendEvent(btnDpd, ariaButtonsText.indexOf(btnDpd))">
			<span *ngIf="dropdownTypeString">{{btnDpd|translate}}</span>
			<span *ngIf="!dropdownTypeString && ariaButtonsTextTranslation === undefined"
				>{{btnDpd}}</span
			>
			<span *ngIf="!dropdownTypeString && ariaButtonsTextTranslation !== undefined"
				>{{ariaButtonsTextTranslation[btnDpd]}}</span
			>
		</button>
	</div>
</div>
