<div class="tripadvisor" *ngIf="!sm && !task" style="margin-top: 10px;">
	<a [href]="link" target="_blank"
		><img src="assets/common/images/tripadvisor.png" style="min-height: 40px !important" />
	</a>
	<div class="triprating">
		<div class="bubble" *ngFor="let celm of ctripadvisorArray"></div>
		<div *ngFor="let celm of htripadvisorArray">
			<div class="hbubble"></div>
			<div class="hobubble"></div>
		</div>
		<div class="obubble" *ngFor="let celm of otripadvisorArray"></div>
		<div class="opinion">
			<a [href]="link">{{ reviews }} {{ "REVIEWS" | translate }}</a>
		</div>
	</div>
</div>

<div class="tripadvisor-mobile" *ngIf="sm && !task">
	<img src="assets/common/images/tripadvisor.png" style="min-height: 40px !important" />
	<div class="triprating-mobile">
		<div class="bubble" *ngFor="let celm of ctripadvisorArray"></div>
		<div *ngFor="let celm of htripadvisorArray">
			<div class="hbubble"></div>
			<div class="hobubble"></div>
		</div>
		<div class="obubble" *ngFor="let celm of otripadvisorArray"></div>
		<div class="opinion">{{ reviews }} {{ "REVIEWS" | translate }}</div>
	</div>
</div>

<div class="tripadvisor-task" *ngIf="task && rating && reviews">
	<img src="assets/common/images/tripadvisor.png" style="min-height: 40px !important" />
	<div class="triprating-task">
		<div class="bubble" *ngFor="let celm of ctripadvisorArray"></div>
		<div *ngFor="let celm of htripadvisorArray">
			<div class="hbubble"></div>
			<div class="hobubble"></div>
		</div>
		<div class="obubble" *ngFor="let celm of otripadvisorArray"></div>
		<div class="opinion">{{ reviews }} {{ "REVIEWS" | translate }}</div>
	</div>
</div>
