<div class="full-modal animated fadeIn" *ngIf="taskModal && tasks.length && taskService.isAllowed">
	<div
		class="ab-modal-box App-Model-Hotel-Photos">
		<div class="header-top flight-details-header">
			<div style="display: flex; justify-content: space-between;">
				<h4>{{ selectedTask && selectedTask.name }}</h4>
			<div>
				<button
					type="button"
					id="modal_close_button"
					class="close"
					(click)="closeModal()"
					style="background: none; color: white; height: 90%; margin: 0 5px">
					<span aria-hidden="true">
						<i class="fa fa-times"></i>
					</span>
				</button>
				<button
					type="button"
					id="modal_close_button"
					class="close"
					(click)="minimizeModal()"
					style="background: none; color: white; height: 100%; margin: 0 5px">
					<span aria-hidden="true">
						<i class="fa fa-minus"></i>
					</span>
				</button>
			</div>
			</div>
			<div class="clearfix"></div>
		</div>
		<div class="modal-overflow" style="overflow-x: hidden; height: auto !important">
			<hotel-task-view
				[hotelItem]="selectedTask.data"
				[closeTask]="closeModal"
				*ngIf="selectedTask && selectedTask.type === 'HOTEL'"
			></hotel-task-view>
		</div>		
	</div>
</div>
<!-- Taskbar  -->
<div class="taskbar d-flex flex-row" *ngIf="tasks.length && taskService.isAllowed && isPageAllowed()">
    <div *ngFor="let task of tasks; index as i">
        <div class="tab" [ngClass]="(selectedTask && selectedTask.id === task.id) ? 'activated' : ''" (click)="showActivity(task)">
            <span><i [ngClass]="'fa '+task.icon" style="padding-right: 10px;"></i></span>
            <!-- <span class="task-title"><a [routerLink]="[{outlets: {'activity': '/user-dashboard'}}]">{{task.name}}</a></span> -->
            <span class="task-title"><a class="task-link" [routerLink]="availabilityRoute">{{task.name}}</a></span>
            <span (click)="closeModal()"><i class="fa fa-close"></i></span>
        </div>
    </div>
</div>