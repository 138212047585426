<section>
	<div class="row not-found">
		<div class="container">
			<p class="large-para text-center"><span class="fa fa-chain-broken"></span>404</p>
			<div class="text-center desc">
				<h1>{{ "404_ALGERIE_BOOKING_MESSAGE" | translate }}</h1>
			</div>
		</div>
	</div>
</section>
